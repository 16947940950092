<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>广场信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-loading="loading">
      <el-button type="primary" @click="reset">刷新</el-button>
      <el-table :data="tableData" border style="width: 100%; display: inline-block;margin-top: 20px" :default-sort="{prop: 'studentNum'}">
        <el-table-column align="center" prop="userId" label="用户Id" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="userName" label="用户昵称" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="image_url" label="头像" width="auto">
          <template slot-scope="scope">
            <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.image"
                :preview-src-list="[scope.row.image]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="create_time" label="上传时间" width="auto">
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
                     :page-size="pageSize"
                     layout="total, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>


  </div>
</template>

<script>
export default {
  data() {
    return {
      userName: window.sessionStorage.getItem("username"),
      loading: false,
      tableData: '',
      // 当前页
      pageNum: 1,
      // 当前每页显示多少条数据
      pageSize: 10,
      total: 0,
    }
  },
  created() {
    this.getuser()
  },
  methods: {
    reset() {
      // 当前页
      this.pageNum = 1
      // 当前每页显示多少条数据
      this.pageSize = 10
      this.loading = true
      this.getuser()
    },
    async getuser() {
      this.loading = true
      console.log("pageNum======>", this.pageNum)
      console.log("pageSize=====>", this.pageSize)
      this.tableData = []
      const {data: res} = await this.$http.post('bk_query_real_image', {
        "pageNum": this.pageNum,
        "pageSize": this.pageSize,
      }, {headers: {'username': window.sessionStorage.getItem('username')}})
      if (res.status === 200) {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      } else {
        this.$message.error(res.msg)
      }

    },
    handleSizeChange(newSize) {
      this.pageSize = newSize
      this.getuser()
    },
    handleCurrentChange(newSize) {
      this.pageNum = newSize
      this.getuser()
    }
  }
}
</script>

<style lang="less" scoped>
.el-card {
  margin-top: 35px;
}


</style>
