<template>
  <div class="login_container">
    <div class="login_box">
      <div class="avatar_box">
        <img src="../assets/ack_logo.jpg" alt="">
      </div>
      <div>
        <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" label-width="0px" class="login_form" v-loading="loading">
          <!-- 用户名 -->
          <el-form-item prop="username">
            <el-input v-model="loginForm.username" prefix-icon="el-icon-user"></el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item prop="password">
            <el-input v-model="loginForm.password" prefix-icon="el-icon-unlock" type="password"></el-input>
          </el-form-item>
          <!-- 按钮 -->
          <el-form-item class="btns">
            <el-button type="primary" @click="login">登录</el-button>
            <el-button type="primary" @click="resetLoginForm">重置</el-button>
             <el-button type="primary" @click="register">注册</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog
        title="注册"
        :visible.sync="dialogVisible"
        width="30%">
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="用户名">
          <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="form.password" @input="validatePasswordLength" show-password></el-input>
        </el-form-item>
        <el-form-item label="再次输入密码" prop="SecondaryPassword">
          <el-input v-model="form.SecondaryPassword" @input="validatePasswordLength" show-password></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="cancel">取 消</el-button>
    <el-button type="primary" @click="submit">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {markRaw} from "vue";

export default {
  data() {
    return {
      form: {
        "username": "",
        "password": "",
        "SecondaryPassword":""
      },
      rules: {
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 12, message: '密码长度必须在 6 到 12 位之间', trigger: 'blur' },
        ],
        SecondaryPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: this.validateConfirmPassword, trigger: 'blur' },
        ],
      },
      dialogVisible: false,
      loading: false,
      loginForm: {
        username: '',
        password: ''
      },
      loginFormRules: {
        username: [
          {required: true, message: '请输入登录名称', trigger: 'blur'},
          {min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入登录密码', trigger: 'blur'},
          {min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur'}
        ],

      }
    }
  },
  methods: {
    validatePasswordLength() {
      if (this.form.password.length > 12) {
        this.form.password = this.form.password.slice(0, 12);
      }else if(this.form.SecondaryPassword.length > 12){
        this.form.SecondaryPassword = this.form.SecondaryPassword.slice(0,12)
      }
    },
    // 验证再次输入密码是否一致
    validateConfirmPassword(rule, value, callback) {
      if (value !== this.form.password) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    },
    submit(){
      this.$refs.form.validate( async valid =>{
        if(valid){
          const {data:res} = await this.$http.post('bk_Register',{"userName":this.form.username,"password":this.form.password})
          if(res.status === 200){
            this.$message.success(res.msg)
            this.dialogVisible = false
          }else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    cancel(){
      this.dialogVisible = false
      this.form.username = ''
      this.form.password = ''
      this.form.SecondaryPassword = ''
    },
    register() {
      this.dialogVisible = true
    },
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields();
    },
    login() {
      this.loading = true
      this.$refs.loginFormRef.validate(async valid => {
        if (!valid) return;
        try {
          const {data: res} = await this.$http.post("bk_login", this.loginForm)
          if (res.status !== 200) {
            this.loading = false
            return this.$message.error(res.msg)
          }
          this.loading = false
          this.$message.success('登录成功')
          window.sessionStorage.setItem('username', res.username)
          this.$router.push('/home')
        } catch {
          this.loading = false
          return this.$message.error("服务器异常")
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.login_container {
  background-color: #2b4b6b;
  height: 100%;
}

.login_box {
  width: 450px;
  height: 300px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.avatar_box {
  height: 130px;
  width: 130px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #eee;
  }
}

.btns {
  display: flex;
  justify-content: flex-end;
}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
</style>
