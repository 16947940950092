<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div>
        <img src="">
        <span>管理后台</span>
      </div>
      <el-button type="info" @click="logout">退出</el-button>
    </el-header>
    <!--  页面主体区域 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="iscollapsed ? '64px' : '200px'">
        <div class="toggle-button" @click="toggleCollapse">|||</div>
        <!-- 侧边栏菜单区域 -->
        <el-menu background-color="#333744" text-color="#fff" active-text-color="#409EFF" :collapse="iscollapsed"
                 :collapse-transition="false" router :default-active="activePath" unique-opened>
                    <!-- 二级菜单 -->
            <el-menu-item :index="'/' + subitem.path" v-for="subitem in menulist" :key="subitem.id"
                          @click="saveNavState('/' + subitem.path )">
              <template slot="title">
                <!-- 图标 -->
                <i :class="scond_icon"></i>
                <!-- 文本 -->
                <span>{{ subitem.menus_name }}</span>
              </template>
            </el-menu-item>
        </el-menu>
      </el-aside>
      <!-- 右侧内容主体 -->
      <el-main>
        <!-- 路由占位符 -->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      // 左侧菜单数据
      menulist: "",
      iconsobj: 'el-icon-reading',
      scond_icon: 'el-icon-s-order',
      iscollapsed: false,
      activePath: ''
    }
  },
  created() {
    this.getMenulist()
    this.activePath = window.sessionStorage.getItem('activePath')
  },
  methods: {
    logout() {
      window.sessionStorage.clear()
      this.$message.success('退出成功')
      this.$router.push('/')
    },
    //获取所有的菜单
    async getMenulist() {
      // const {data: res} = await this.$http.get('menus',{headers:{"Username":window.sessionStorage.getItem("username")}})
      const {data: res} = await this.$http.post('bk_menus',{},{headers:{"username":window.sessionStorage.getItem('username')}})
      if (res.status === 500) {
        console.log(res.msg)
        this.$message.error(res.msg)
        await this.$router.push('/')
      }
      this.menulist = res.data
    },
    // 点击按钮，切换菜单的折叠和展开
    toggleCollapse() {
      this.iscollapsed = !this.iscollapsed
    },
    //保存链接的激活状态
    saveNavState(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    }
  }
};
</script>

<style lang="less" scoped>
.el-header {
  background-color: #373D41;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;

  > div {
    display: flex;
    align-items: center;

    span {
      margin-left: 15px;
    }
  }
}

.el-aside {
  background-color: #333744;

  .el-menu {
    border-right: none;
  }
}

.el-main {
  background-color: #EAEDF1;
}

.home-container {
  height: 100%;
}

.toggle-button {
  background-color: #4A5064;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
</style>
